import * as React from "react"
import Contact from "../components/contact/contact"
import HeroAbout from "../components/hero/about-services"
import TextRight from "../components/image-left-text-right"
import Layout from "../components/layout"
import Seo from "../components/seo"
import about from "../images/drone.jpeg"

const About = () => (
  <Layout>
    <Seo title="Services" />

    <HeroAbout
      title="About Us"
      subTitle="Leading the way in a wide range of building services from concept to completion. We specialise in new builds, restoration work and commerical fitouts"
    />

    <TextRight
      title="About us"
      textOne="We are an established business, priding ourselves on taking our clients projects from concept to completion.

We specialise in a wide range of building services from New Builds to extensions, restoration projects and commerical fit outs."
      button="GET A FREE QUOTE"
      image={about}
      link="#contact"
    />

    <Contact />
  </Layout>
)

export default About
